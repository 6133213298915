import { render, staticRenderFns } from "./App.vue?vue&type=template&id=f56966d4&scoped=true&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=f56966d4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f56966d4",
  null
  
)

export default component.exports